import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Canvas from 'canvas';
import * as JsBarcode from 'jsbarcode';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { lastValueFrom } from 'rxjs';
import { fixTermsUrl } from '../globals';
import { TownshipPublicSettings, Voucher, VoucherGroup } from '../interfaces';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../app.component';

@Component({
  selector: 'app-landing-email',
  templateUrl: './landing-email.component.html',
  styleUrls: ['./landing-email.component.scss'],
})
export class LandingEmailComponent implements OnInit {
  waiting: boolean = true;
  dataToDisplay: {
    header: string;
    voucherGroup: VoucherGroup;
    township: TownshipPublicSettings;
    validUntilDate: any;
    voucher: Voucher;
    validDate: boolean;
  };
  voucherGroup: VoucherGroup;
  township: TownshipPublicSettings;
  townshipId: string;
  voucher: Voucher;
  code: string; // number is not accepted by plain js in parameters
  barcodeUrl: string;
  townshipUrlFixed: string;
  townshipTermsUrlFixed: string;

  validCoupon: boolean = true;
  resendingEmail: boolean;

  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private fns: AngularFireFunctions
  ) {}

  async ngOnInit(): Promise<any> {
    const url = new URL(window.location.href);
    this.code = url.searchParams.get('code');
    this.townshipId = url.searchParams.get('townshipId');

    const callBody = {
      code: this.code,
      townshipId: this.townshipId,
    };

    const callable = this.fns.httpsCallable(`httpGetEmailData`);
    const response = await lastValueFrom(callable(callBody));

    console.log('response', response);
    this.townshipUrlFixed = fixTermsUrl(this.township.termsUrl);
    this.townshipTermsUrlFixed = fixTermsUrl(this.township.url);

    if (response.message === 'succeed') {
      this.dataToDisplay = response.data;
      console.log('dataToDisplay', this.dataToDisplay);
      this.voucherGroup = this.dataToDisplay.voucherGroup;
      this.township = this.dataToDisplay.township;
      this.voucher = this.dataToDisplay.voucher;
      if (this.voucher.claimDate) {
        this.validCoupon = false;
      }
      if (!this.dataToDisplay.validDate) {
        this.validCoupon = false;
      }
      if (this.voucherGroup.name === 'ZZZ vervallen') {
        return this.snackbar.open('Deze bon is vervallen', 'X', {
          duration: 5000,
        });
      }

      const canvas = Canvas.createCanvas(400, 200);
      JsBarcode(canvas, this.code, {
        marginLeft: 48,
        marginRight: 48,
        marginTop: 10,
        marginBottom: 10,
      });
      this.barcodeUrl = canvas.toDataURL();

      this.waiting = false;
    } else {
      this.snackbar.open(
        'Er is iets misgegaan bij het ophalen van je gegevens',
        'X',
        {
          duration: 5000,
        }
      );
    }
  }

  async resendEmail() {
    this.resendingEmail = true;
    try {
      const callBody = {
        code: this.code,
        email: this.voucher.email,
        townshipId: this.townshipId,
      };
      const callable = httpsCallable(functions, 'httpSendVoucherMail');
      await callable(callBody).then((response) => {
        if (response.data['status'] == 'success') {
          this.snackbar.open('E-mail is opnieuw verzonden', 'X', {
            duration: 5000,
          });
        } else {
          this.snackbar.open(
            'Er is iets misgegaan met opnieuw verzenden van de e-mail',
            'X',
            {
              duration: 5000,
            }
          );
        }
      });
      this.resendingEmail = false;
    } catch (e) {
      console.error(e);
      this.resendingEmail = false;
      this.snackbar.open(
        'Er is iets misgegaan met opnieuw verzenden van de e-mail',
        'X',
        {
          duration: 5000,
        }
      );
    }
  }
}
