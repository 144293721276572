import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  VoucherGroup,
  Order,
  TownshipPublicSettings,
} from 'src/app/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/app/app.component';

@Component({
  selector: 'app-buy-success',
  templateUrl: './buy-success.component.html',
  styleUrls: ['./buy-success.component.scss'],
})
export class BuySuccessComponent implements OnInit {
  env = environment;
  redirectUrl: string;
  order: Order;
  titleAbove: string;
  titleBelow: string;
  tableArticleVoucher: string;
  tableArticleCosts: string;
  tableArticleTotal: string;

  awaitingResponse: boolean = true;

  displayedColumns: string[] = ['Artikel', 'Aantal', 'Prijs'];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  data: any[] = [];

  failed = false;

  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);
    this.route.params.subscribe(async (params) => {
      const orderData = {
        voucherGroupdId: params.voucherGroupdId,
        townshipId: params.townshipId,
        orderId: params.orderId,
      };
      const callable = httpsCallable(functions, 'httpGetOrder');
      const result = await callable(orderData);
      if (result.data) {
        this.awaitingResponse = false;
        const voucherGroup = result.data['voucherGroup'] as VoucherGroup;
        const order = result.data['order'] as Order;

        this.order = order;
        const township = result.data['township'] as TownshipPublicSettings;
        if (voucherGroup.redirectUrl) {
          this.redirectUrl = voucherGroup.redirectUrl;
        }
        switch (order.status) {
          case 'done':
          case 'paid':
            this.titleAbove = await this.getTranslation(
              'succeed.message-above'
            );
            this.titleBelow = await this.getTranslation(
              'succeed.message-under'
            );
            this.tableArticleVoucher = await this.getTranslation(
              'succeed.article-voucher'
            );
            this.tableArticleCosts = await this.getTranslation(
              'succeed.article-costs'
            );
            this.tableArticleTotal = await this.getTranslation(
              'succeed.article-total'
            );
            let envName = township.voucherPrefix;
            envName = envName.charAt(0).toUpperCase() + envName.slice(1);
            this.data = [
              {
                article: `${envName} ${this.tableArticleVoucher} €${order.price}`,
                amount: `${order.amount}x`,
                price: `	€${this.getCurrencyString(order.price * order.amount)}`,
              },
              {
                article: `${this.tableArticleCosts}`,
                amount: `1x`,
                price: `	€${this.getCurrencyString(order.administrationCost)}`,
              },
              {
                article: `${this.tableArticleTotal}`,
                amount: ``,
                price: `	€${this.getCurrencyString(order.totalPrice)}`,
              },
            ];
            break;
          case 'open':
            if (voucherGroup.paymentProvider === 'ideal') {
              this.titleAbove = await this.getTranslation(
                'processing.message-above-ideal'
              );
              this.titleBelow = await this.getTranslation(
                'processing.message-under-ideal'
              );
            } else {
              this.titleAbove = await this.getTranslation(
                'processing.message-above'
              );
              this.titleBelow = await this.getTranslation(
                'processing.message-under'
              );
            }
            break;
          case 'canceled':
            this.titleAbove = await this.getTranslation(
              'cancelled.message-above'
            );
            this.titleBelow = await this.getTranslation(
              'cancelled.message-under'
            );
            break;
          case 'expired':
            this.titleAbove = await this.getTranslation(
              'expired.message-above'
            );
            this.titleBelow = await this.getTranslation(
              'expired.message-under'
            );
            break;
          case 'failed':
            this.titleAbove = await this.getTranslation('failed.message-above');
            this.titleBelow = await this.getTranslation('failed.message-under');
            break;
          default:
            this.titleAbove = await this.getTranslation('failed.message-above');
            this.titleBelow = await this.getTranslation('failed.message-under');
            break;
        }
      }
    });
  }

  openWindow(url: string) {
    window.open(url, '_blank');
  }

  getTranslation(objName): any {
    const res = this.translate.instant(`buy.${objName}`);
    return res;
  }

  getCurrencyString(number: any) {
    if (!number) {
      number = 0;
    }
    let newNumber;
    if (!number.toString().includes('.')) {
      // this isnt a decimal
      newNumber = `${number.toString()},-`;
    } else {
      newNumber = `${number.toFixed(2)}`;
      newNumber = newNumber.replace('.', ',');
    }
    return newNumber;
  }
}
